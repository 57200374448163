<template>
  <insert-document/>
</template>

<script>
import InsertDocument from '@/views/admin/accountancy/documents/insert/insertDocument'

export default {
  name: 'insertTransaction',
  components: {InsertDocument},
  data () {
    return  {
      actions: [
        {
          toolbar: [
            {
              id: 'insertDocumentBTN',
              color: 'success',
              icon: 'icon-check',
              iconPack: 'feather'
            }
          ],
          leftToolbar: [
            {
              id: {name: 'treasuryTransactions'},
              type: 'link',
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 m-0 mx-auto')
    }, 75)
  }
}
</script>

<style scoped>

</style>
